import './home.css';
import Main from './Main/Main';
import Reviews from './Reviews/Reviews';
import Services from './Services/Services';
import Introduction from './Introduction/Introduction';

export default function Home() {
  return (
    <div id="home" className='bg'>
      <Main />
      <div className="home-container container">
        <Introduction />
        <Reviews />
      </div>
    </div>
  );
}
