import * as React from 'react';
import './review.css';
import costumer from '../../data/reviews/costumer-avatar.png';

export interface IReviewProps {
  firstName: string;
  lastName: string;
  reviewText: string;
}

export default function Review({
  firstName,
  lastName,
  reviewText,
}: IReviewProps) {
  return (
    <div className="reviews__review">
      <div className="reviews__review__title">
        <img src={costumer} alt="costumer" />
        <h2 className="reviews__review__name">{`${firstName} ${lastName}`}</h2>
      </div>
      <p className="reviews__review__text">{reviewText}</p>
    </div>
  );
}
