import { useLoaderData, useNavigate } from 'react-router-dom';
import { HiArrowNarrowLeft } from 'react-icons/hi';
import { projects, ProjectType } from './Portfolio';
import './css/project-1.css';
// import './css/project-2.css';
import './css/project-3.css';
import './css/project-4.css';
import './css/project-5.css';
import './css/project-6.css';
import './css/project-7.css';
import './project.css';

type LoaderReturn = {
  project: ProjectType;
};

export async function loader({ params }: any): Promise<LoaderReturn> {
  const project = projects[params.projectId - 1];
  return { project };
}
export default function Project() {
  const { project } = useLoaderData() as LoaderReturn;
  const { images, description } = project;

  const navigate = useNavigate();

  return (
    <section id='project' className='bg'>
      <div className="project-container container">
        <h1>About this Project</h1>
        <p>{description}</p>
        <button
          type="button"
          className="btn-return btn btn-blue"
          onClick={() => navigate(-1)}
        >
          <HiArrowNarrowLeft />
          {/* Go Back */}
        </button>
        <div className="project__gallery">
          {images.map((image) => (
            <div className="project__image" id={image} />
          ))}
        </div>
      </div>
    </section>
  );
}
