import './values-principles.css';

type Values = {
  title: string;
  name: string;
  paragraphs: string[];
};
const values: Values[] = [
  {
    title: 'Always Safe',
    name: 'safe',
    paragraphs: [
      "Regardless of whether you require new siding installation or a full replacement, we guarantee the safety and security of your property throughout the entire project. Our company values the importance of giving attention and care to each client's home and it is something that we do not compromise.",
      'Our teams undergo thorough yearly training in partnership with the Washington State Department of Labor and Industries to guarantee on-site safety during projects. The program prioritizes safety measures related to residential, environmental, and contractor concerns.',
      'It is crucial to maintain a hazard-free work environment, including the absence of sharp implements, unstable scaffolding, and other potential hazards, while undertaking installation tasks.',
    ],
  },
  {
    title: 'Be Helpful',
    name: 'helpfulness',
    paragraphs: [
      'Our experienced and adept siding installation teams, overseen by an Construction project manager, are readily accessible to address any queries related to the project. With our extensive 10-year tenure in the siding industry, we have developed a comprehensive understanding of our clientele.',
      "Moreover, we prioritize the minute details and take great care in listening to our clients' feedback and addressing any issues they may have. Our efforts are also geared towards creating homeowner confidence throughout the siding process and ensuring that they receive an impeccably finished home.",
    ],
  },

  {
    title: 'On Time',
    name: 'time',
    paragraphs: [
      'We understand how important your project timeline is to you. Which is why we work diligently to complete our installations on schedule, as planned. Our experienced crews are reliable and always arrive on time. They are prepared to complete the goals for each project, and we take care to avoid any unforeseen delays. If any complication does arise, we will promptly notify you and work together to find a solution.',
      'Our crews are reliable and arrive on time, ready to complete the goals for each project. Moreover, we do our very best to avoid any unwanted and unnecessary delays. And most importantly, if any unforeseen situations were to arise, we’ll notify you immediately and discuss potential solutions.',
    ],
  },
  {
    title: 'We Communicate',
    name: 'communication',
    paragraphs: [
      'To ensure a superior siding experience, it is crucial to maintain a high level of communication with our clients. Our primary objective is to keep our clients informed throughout the installation process. This step is crucial to ensure that the client remains aware of what is happening during the provision of our services.',
      'We are dedicated to meeting the needs of your project in a timely manner using your preferred communication method, whether it be text, phone or email. We aim to eliminate any inconveniences or misunderstandings by offering clear and precise information about the scope of the project and total cost involved.',
    ],
  },
];

export default function ValuesPrinciples() {
  return (
    <div className="values-container">
      <h1>Values & Principles</h1>
      <div className="section-flex">
        {values.map(({ title, name, paragraphs }) => (
          <div className="cell">
            <div className="inner">
              <div className="row">
                <div className="col xs-2">
                  <div className={`values-img values-${name}`} />
                </div>
                <div className="col xs-10">
                  <h3>{title}</h3>
                  {paragraphs.map((text) => (
                    <p>{text}</p>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
