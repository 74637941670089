import * as React from 'react';
import './services.css';
import Product from './Products/Product';
import LearnMore from './Learn More/LearnMore';

export type Product = {
  imageUrl: string;
  name: string;
  description: string[];
};

export const products: Product[] = [
  {
    imageUrl: '../../data/services/service-window.png',
    name: 'Framing',
    description: [
      'We have decades of experience constructing frameworks of buildings and other structures in strict accordance with construction plans. Our primary focus is to ensure that structures are of the highest level of precision, from structural frame elements to window and door frames or staircases and flooring.',
      'All our work strictly complies with current building codes and safety regulations.',
    ],
  },
  {
    imageUrl: '../../data/services/service-door.png',
    name: 'Electrical',
    description: [
      'Let me know if you are looking for reliable and professional electrical services. Look no further than our company!',
      'We offer a wide range of electrical services to meet your needs, including installation, repair, and maintenance for residential, commercial, and industrial properties. Our highly trained and experienced electricians are dedicated to providing high-quality workmanship and exceptional customer service.',
      'Whether you need help with electrical wiring, panel upgrades, lighting installation, or any other electrical project, we have the expertise to do the job right. We use only the highest quality materials and state-of-the-art equipment to ensure your satisfaction.',
      'We understand that electrical issues can be stressful and disruptive, so we offer prompt and efficient service to minimize downtime. We also provide emergency services 24/7 to address any unexpected electrical problems.',
      'Our company is fully licensed, bonded, and insured, giving you peace of mind knowing that you are working with a reputable and reliable electrical services provider. We pride ourselves on our commitment to safety and adhere to all industry standards and regulations.',
      "Please contact us today to schedule a consultation and learn more about how we can help you with your electrical needs. We'd like to take care of your electrical issues so you can focus on what matters most.",
    ],
  },
  {
    imageUrl: '../../data/services/service-plumbing.png',
    name: 'Plumbing',
    description: [
      'Whether facing a new construction project or a broken pipe, 1st Choice Construction is ready to deliver prompt, quality service in the residential, commercial, and general repair areas. We can assist you with a wide range of plumbing services with a quick response and at very competitive rates. ',
      'Our expertise includes repairing, replacing, installing, and cleaning plumbing fixtures, pipes, drains, and water heaters. We use high-tech electronic detection equipment to find leaks in water and gas lines, state-of-the-art video cameras to locate clogs in drain and sewer lines, and high-pressure water jetting to remove tough pipe blockages.',
      'From emergency needs to residential or commercial projects and services, we are ready to respond quickly and eager to see how we may help you with any plumbing needs. Do not hesitate to contact us through any of the means below.',
      'Are you looking for a reliable plumbing company that can handle all your plumbing needs? Look no further than our company!',
      'We understand that plumbing issues can be stressful and disruptive, so we offer 24/7 emergency services to address any unexpected plumbing problems. Our team is dedicated to providing exceptional customer service and ensuring that each job is done to the highest standards.',
      'We offer a wide range of plumbing services, including leak detection, drain cleaning, water heater repair and installation, sewer line repair, bathroom and kitchen plumbing, and more. Our team uses the latest equipment and techniques to complete each job efficiently and effectively.',
      "Contact us today to schedule a consultation and learn more about how we can assist you with your plumbing needs. We'd like to take care of your plumbing issues so you can focus on what matters most.",
    ],
  },
  {
    imageUrl: '../../data/services/service-deck.png',
    name: 'Insulation',
    description: [
      'Are you tired of high energy bills and uncomfortable temperatures in your home or office? Look no further than 1st Choice Construction!',
      'We specialize in providing top-quality insulation services for residential and commercial properties. Our team of experts will assess your property to determine the best insulation solution for your needs and budget. We offer a range of insulation options, including spray foam, blown-in, and fiberglass insulation.',
      'Proper insulation can significantly improve the energy efficiency of your property, resulting in lower energy bills and a more comfortable living or working environment. Our insulation services can also help reduce noise levels and improve indoor air quality.',
      'We use only the highest quality materials and state-of-the-art equipment to ensure a job well done. Our team of experienced professionals is dedicated to providing exceptional customer service and completing each project promptly and efficiently.',
      'Our company is fully licensed, bonded, and insured, giving you peace of mind knowing that you are working with a reputable and reliable insulation services provider. We pride ourselves on our commitment to safety and adhere to all industry standards and regulations.',
      'Please contact us today to schedule a consultation and learn more about how we can help you with your insulation needs. Let us help you save money on your energy bills and create a more comfortable living or working environment.',
    ],
  },
  {
    imageUrl: '../../data/services/service-insulation.png',
    name: 'Windows & Doors',
    description: [
      'Are you looking for a reliable and professional windows and doors installation company? Look no further than our company!',
      'We offer a wide range of window and door installation services to meet your needs, including replacement, installation, and repair for residential and commercial properties. Our highly trained and experienced installers are dedicated to providing high-quality workmanship and exceptional customer service.',
      'We offer a variety of window and door styles, including energy-efficient options to help you save on your energy bills. Our team will work with you to determine the best solution for your needs and budget.',
      'We understand that window and door installations can be a significant investment, so we use only the highest quality materials and state-of-the-art equipment to ensure your satisfaction. Our team is committed to completing each job efficiently and effectively, minimizing disruption to your daily routine.',
      'Our company is fully licensed, bonded, and insured, giving you peace of mind knowing that you are working with a reputable and reliable windows and doors installation provider. We pride ourselves on our commitment to safety and adhere to all industry standards and regulations.',
      'Contact us today to schedule a consultation and learn how we can assist you with your windows and doors installation needs. Let us enhance the beauty and functionality of your property with our expert installation services.',
    ],
  },
  {
    imageUrl: '../../data/services/service-Flooring & Millwork.png',
    name: 'Flooring & Millwork',
    description: [
      'We offer a wide range of floor and baseboard installation services to meet your needs, including installation, repair, and maintenance for residential and commercial properties. Our highly trained and experienced installers are dedicated to providing high-quality workmanship and exceptional customer service.',
      'We offer various flooring options, including hardwood, laminate, tile, and carpet, to fit your design style and budget. Our team will work with you to determine the best solution for your needs and ensure the installation is done correctly.',
      'In addition to flooring installation, we also provide custom baseboard installation services, including baseboards, crown molding, and trim. We can help you achieve a seamless and polished look for your property.',
      'We understand that floor and baseboard installations can be a significant investment, so we use only the highest quality materials and state-of-the-art equipment to ensure your satisfaction. Our team is committed to completing each job efficiently and effectively, minimizing disruption to your daily routine.',
      'Our company is fully licensed and insured, giving you peace of mind knowing that you are working with a reputable and reliable floor and baseboard installation provider. We pride ourselves on our commitment to safety and adhere to all industry standards and regulations.',
      'Contact us today to schedule a consultation and learn how we can assist you with your floor and baseboard installation needs. Let us enhance the beauty and functionality of your property with our expert installation services.',
    ],
  },
  {
    imageUrl: '',
    name: 'Siding',
    description: [
      'We offer a wide range of siding installation services to meet your needs, including installation, repair, and maintenance for residential and commercial properties. Our highly skilled and experienced installers are dedicated to providing high-quality workmanship and exceptional customer service.',
      'We offer various siding options, including vinyl, fiber cement, and wood, to fit your design style and budget. Our team will work with you to determine the best solution for your needs and ensure the installation is done correctly.',
      'We understand that siding installation can be a significant investment, so we use only the highest quality materials and state-of-the-art equipment to ensure your satisfaction. Our team is committed to completing each job efficiently and effectively, minimizing disruption to your daily routine.',
      'Our company is fully licensed and insured, giving you peace of mind knowing that you are working with a reputable and reliable siding installation provider. We pride ourselves on our commitment to safety and adhere to all industry standards and regulations.',
      'Contact us today to schedule a consultation and learn more about how we can assist you with your siding installation needs. Let us help enhance the beauty and functionality of your property with our expert installation services.',
    ],
  },
  {
    imageUrl: '',
    name: 'Gutters',
    description: [
      'We offer a wide range of gutters installation services to meet your needs, including installation, repair, and maintenance for residential and commercial properties. Our highly trained and experienced installers are dedicated to providing high-quality workmanship and exceptional customer service.',
      'We offer a variety of gutter styles and materials, including aluminum, copper, and steel, to fit your design style and budget. Our team will work with you to determine the best solution for your needs and ensure the installation is done correctly.',
      'We understand gutters installation can be a significant investment, so we use only the highest quality materials and state-of-the-art equipment to ensure your satisfaction. Our team is committed to completing each job efficiently and effectively, minimizing disruption to your daily routine.',
      'Our company is fully licensed and insured, giving you peace of mind knowing that you are working with a reputable and reliable siding installation provider. We pride ourselves on our commitment to safety and adhere to all industry standards and regulations.',
      'Contact us today to schedule a consultation and learn more about how we can assist you with your siding installation needs. Let us help enhance the beauty and functionality of your property with our expert installation services.',
    ],
  },
  {
    imageUrl: '',
    name: 'Painting',
    description: [
      '1st Choice Construction offers various painting services to meet your needs, including interior and exterior painting for residential and commercial properties. Our team of highly skilled and experienced painters is dedicated to providing high-quality workmanship and exceptional customer service.',
      'We use only the highest quality paints and materials to ensure a beautiful, long-lasting finish. Our team is committed to completing each job efficiently and effectively, minimizing disruption to your daily routine.',
      "We offer a variety of painting services, including color consultations, wallpaper removal, drywall repair, and more. Whether you're looking to freshen up a single room or transform your entire property, we have the expertise and experience to do the job right.",
      'Our company is fully licensed, bonded, and insured, giving you peace of mind knowing that you are working with a reputable and reliable painting services provider. We pride ourselves on our commitment to safety and adhere to all industry standards and regulations.',
      "Contact us today to schedule a consultation and learn more about how we can assist you with your painting needs. We'd like to help you transform your property with our expert painting services.",
    ],
  },
];

export default function Services() {
  return (
    <div id="services" className="bg">
      <div className="services__text-background">
        <h1>Services</h1>
      </div>
      <div className="services-container container">
        <div className="services__main-text">
          <h2>1st Choice Construction, acting as General Contractor, will:</h2>
          <ul className="services__text-list">
            <li>
              keep the project on the appropriate timeline and is directly
              responsible for ensuring a quality build within the quoted
              timeframe.
            </li>
            <li>
              manage crews engaged on the job. Skilled laborers will often need
              to complete portions of the project, including plumbing,
              electrical, and framing.
            </li>
            <li>
              order materials related to the build and work with the vendors to
              ensure the correct deliveries are taking place according to the
              timeline. We will constantly contact wholesalers and supply stores
              to stock the site properly.
            </li>
            <li>
              communicate the project status throughout the build with the
              client and other pertinent parties.
            </li>
          </ul>
        </div>
        <div className="services__products">
          {products.map((product) => (
            <Product product={product} key={`container-${product.name}`} />
          ))}
        </div>
        {/* <p className="services__description">
        We thought of every angle from our client&rsquo;s perspective and
        provided it to them in real-time. This level of efficiency provides our
        clients with the luxury of time to focus on other demanding aspects of
        their job and life while <b> 1st Choice Construction&rsquo;s </b>
        well-oiled machine goes to work.
        </p>
        <p className="services__description two">
        Our entire team stays educated on our latest software innovations in
        order to enhance our client&rsquo;s experience. We are determined to
        keep all of our projects updated according to what&rsquo;s happening on
        a daily basis in the office and on the ground so our clients and vendors
        never miss a vital communication or detail
      </p> */}
        <LearnMore products={products} />
      </div>
    </div>
  );
}
