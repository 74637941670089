import * as React from 'react';
import type { Product } from '../Services';
import './product.css';

export interface IProductProps {
  product: Product;
}

export default function Product({ product }: IProductProps) {
  const { imageUrl, name } = product;
  const idName = name.split(' ')[0]
  return (
    <div className="product-container">
      <div className="product__image" id={idName} />
      <span className="product__name">{name}</span>
    </div>
  );
}
