import * as React from 'react';
import './learn-more.css';
import ProductDescription from './ProductDescription';
import { Product } from '../Services';

interface LearnMoreProps {
  products: Product[];
}

export default function LearnMore({ products }: LearnMoreProps) {
  return (
    <div className="learn-more-container">
      <h1>Learn More About 1st Choice Construction Products</h1>
      {/* <p>
        Allura Fiber Cement products are made with an advanced formula that is
        ideal for even the most extreme climates. Allura products are
        fire-resistant, moisture-resistant and weather-resistant. From
        traditional to modern, Allura has products to fit any style in every
        region.
      </p> */}
      {products.map((product) => (
        <ProductDescription
          key={`description-${product.name}`}
          product={product}
        />
      ))}
    </div>
  );
}
