import * as React from 'react';
import { GoTriangleRight } from 'react-icons/go';
import { useState } from 'react';
import { Product } from '../Services';
import './product-description.css';

export interface IProductDescriptionProps {
  product: Product;
}

export default function ProductDescription({
  product,
}: IProductDescriptionProps) {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [triangleId, setTriangleId] = useState<string>('tr-static');
  const [descriptionId, setDescriptionId] = useState<string>('desc-hidden');

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
    setTriangleId(triangleId === 'tr-static' ? 'tr-expanded' : 'tr-static');
    setDescriptionId(
      descriptionId === 'desc-hidden' ? 'desc-expanded' : 'desc-hidden',
    );
  };

  const { name, description } = product;
  return (
    <div className="product-description-container">
      <div className="product-description-title">
        <GoTriangleRight
          id={triangleId}
          className={`triangle-expand ${name}-triangle text-blue`}
          onClick={toggleExpand}
        />
        <span
          className="product-description-name"
          role="button"
          tabIndex={0}
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              toggleExpand();
            }
          }}
          onClick={toggleExpand}
        >
          {name}
        </span>
      </div>
      <div id={descriptionId} className="product-description-text">
        {description.map((paragraph) => (
          <p key={paragraph}>{paragraph}</p>
        ))}
      </div>
    </div>
  );
}
