import * as React from 'react';
import './contact-us.css';
import { useState, useEffect } from 'react';
import PopUp from './PopUp';
import { products } from '../Services/Services';

declare global {
  interface Window {
    Email: any;
  }
}

export default function ContactUs() {
  const [nameValue, setHameValue] = useState<string>('');
  const [emailValue, setEmailValue] = useState<string>('');
  const [serviceValue, setServiceValue] = useState<string>('Framing');
  const [messageValue, setMessageValue] = useState<string>('');
  const [popupVisibility, setPopupVisibility] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [contactUsSectionVisibility, setContactUsSectionVisibility] =
    useState<boolean>(true);
  const [isSmallDevice, setIsSmallDevice] = useState(false);

  useEffect(() => {
    const mediaQuery: MediaQueryList = window.matchMedia('(max-width: 800px)');
    setIsSmallDevice(mediaQuery.matches);

    const handler = (event: MediaQueryListEvent) => {
      setIsSmallDevice(event.matches);
    };

    mediaQuery.addEventListener('change', handler);

    return () => {
      mediaQuery.removeEventListener('change', handler);
    };
  }, []);

  const contactUsSectionClassName = `contact-us-container container bg ${
    !contactUsSectionVisibility ? 'blurred' : ''
  }`;

  const checkFormValidation = (
    formDataArr: [string, FormDataEntryValue][],
  ): boolean => {
    let isValid = true;

    formDataArr.forEach((nameAndValueArr) => {
      if (nameAndValueArr[1] === '') {
        isValid = false;
      }
    });
    return isValid;
  };

  const resetFormValues = () => {
    setHameValue('');
    setEmailValue('');
    setServiceValue('Windows');
    setMessageValue('');
  };

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const data = new FormData(event.target as HTMLFormElement);
    const body = Array.from(data.entries());
    if (checkFormValidation(body)) {
      setIsLoading(true);
      window.Email.send({
        SecureToken: '9b3ec74b-9678-4bf4-9410-dd06fcf54cd8',
        To: 'precision-designs@outlook.com',
        From: 'email_sendler@1choice.construction',
        Subject: 'Mails from Costumers',
        Body: `<b>Name: </b>${nameValue} <br> <b>Email: </b>${emailValue} <br> <b>Service: </b>${serviceValue} <br> <b>Message: </b>${messageValue}`,
      })
        .then((message: string) => {
          setIsLoading(false);
          setContactUsSectionVisibility(false);
          setPopupVisibility(true);
          resetFormValues();
        })
        .catch((message: string) => {
          setIsLoading(false);
          alert('Something went wrong...');
        });
    }
  };

  return (
    <>
      <div className="contact-us__text-background">
        <h1>Let&rsquo;s talk</h1>
      </div>
      <div className={contactUsSectionClassName}>
        <div className="contact-us__text-container ">
          <div className="contact-us__text__paragraph">
            <p>
              We, 1st Choice Construction, are at arm&apos;s length from you,
              and reaching out to us has never been easier. Bought the land and
              are looking for the design and permit estimate? Are you planning
              to start new construction, remodel an existing house, or build an
              addition? No problem! Whether you have already pulled the permit
              or not, we are here to help with an all-inclusive service—no more
              shopping around and managing multiple third parties and hearing
              them blame each other. We are here to help!
            </p>
            <p>
              Please describe your project in our secure form below, and we will
              contact you within 48 hours! Ensure you include your contact
              information and preferred date/time for a consultation. One of our
              sales reps will draft a non-binding offer letter that consists of
              any concerns or preferences you may have about the process,
              materials, time frame, or additional services.
            </p>
            <p>No pressure; take your time to weigh our proposal.</p>
            <p>
              At 1st Choice Construction, we are committed to helping you to
              achieve your construction goals.
            </p>
            {isSmallDevice && <p>Scroll down to schedule an estimate now!</p>}
          </div>
          <h3 className="contact-us__text__email">Email</h3>
          <span>estimate@1choice.construction</span>
          <div className="contact-us__touch-device">
            <h3>Stay In Touch</h3>
            <i style={{ fontSize: '1rem' }}>
              1st Choice Construction Inc. <br /> PO Box 315 <br /> Bellevue WA
              98009 <br />
              253-569-4588 <br /> 206-747-7717
            </i>
            <h3>Areas of Service</h3>
            <span>
              King County, WA; Pierce County, WA; Snohomish County, WA
            </span>
            <h3>Hours of Operations</h3>
            <pre style={{ fontSize: '1rem' }}>
              Monday 8-6 <br />
              Tuesday 8-6 <br />
              Wednesday 8-6
              <br />
              Thursday 8-6
              <br />
              Friday 8-5
              <br />
              Saturday by appointment only
              <br />
            </pre>
          </div>
        </div>
        <div className="contact-us__form-container">
          <form action="submit" method="POST" onSubmit={handleFormSubmit}>
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              spellCheck="false"
              value={nameValue}
              onChange={(e) => setHameValue(e.target.value)}
            />
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              spellCheck="false"
              value={emailValue}
              onChange={(e) => setEmailValue(e.target.value)}
            />
            <label htmlFor="service">What service are you interested in</label>
            <select
              name="service"
              id="service"
              value={serviceValue}
              onChange={(e) => setServiceValue(e.target.value)}
            >
              {products.map((product) => (
                <option key={product.name} value={product.name}>
                  {product.name}
                </option>
              ))}
            </select>
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              name="message"
              spellCheck="false"
              value={messageValue}
              onChange={(e) => setMessageValue(e.target.value)}
            />
            <button className="form-btn btn btn-blue" type="submit">
              {isLoading ? 'Sending...' : 'Send'}
            </button>
          </form>
          <div className="contact-us__touch-phone">
            <h3>Stay In Touch</h3>
            <i style={{ fontSize: '1rem' }}>
              1st Choice Construction Inc. <br /> PO Box 315 <br /> Bellevue WA
              98009 <br />
              253-569-4588 <br /> 206-747-7717
            </i>
            <h3>Areas of Service</h3>
            <span>
              King County, WA; Pierce County, WA; Snohomish County, WA
            </span>
            <h3>Hours of Operations</h3>
            <pre style={{ fontSize: '1rem' }}>
              Monday 8-6 <br />
              Tuesday 8-6 <br />
              Wednesday 8-6
              <br />
              Thursday 8-6
              <br />
              Friday 8-5
              <br />
              Saturday by appointment only
              <br />
            </pre>
          </div>
        </div>
      </div>
      <PopUp
        setPopupVisibility={setPopupVisibility}
        setContactUsSectionVisibility={setContactUsSectionVisibility}
        popupVisibility={popupVisibility}
      />
    </>
  );
}
