import * as React from 'react';
import './photos-carousel.css';
import { useEffect, useState, Children, cloneElement } from 'react';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

interface IPhotosCarouselProps {
  children: React.ReactNode;
}

export default function PhotosCarousel({ children }: IPhotosCarouselProps) {
  const [photos, setPhotos] = useState<React.ReactNode[]>([]);
  const [offset, setOffset] = useState<number>(0);
  const [isLeftOffsetAvailable, setLeftOffsetAvailability] = useState(false);
  const [isRightOffsetAvailable, setRightOffsetAvailability] = useState(true);
  const PHOTO_WIDTH = '750';

  const handleRightArrowClick = () => {
    const filteredChildren = Children.toArray(children).filter(
      (child) => child != null,
    );
    const max = -PHOTO_WIDTH * (filteredChildren.length - 1);
    if (!isLeftOffsetAvailable) {
      setLeftOffsetAvailability(true);
    }
    setOffset((currentOffset) => {
      const newOffset = currentOffset - +PHOTO_WIDTH;
      if (max === newOffset) {
        setRightOffsetAvailability(false);
      }
      return newOffset;
    });
  };

  const handleLeftArrowClick = () => {
    const min = 0;
    if (!isRightOffsetAvailable) {
      setRightOffsetAvailability(true);
    }
    setOffset((currentOffset) => {
      const newOffset = currentOffset + +PHOTO_WIDTH;
      if (min === newOffset) {
        setLeftOffsetAvailability(false);
      }
      return newOffset;
    });
  };

  useEffect(() => {
    const filteredChildren = Children.toArray(children).filter(
      (child) => child != null,
    );
    const photosArray = filteredChildren.map((child) =>
      cloneElement(child as React.ReactElement, {
        style: {
          height: '100%',
          minWidth: `${PHOTO_WIDTH}px`,
          maxWidth: `${PHOTO_WIDTH}px`,
        },
      }),
    );
    setPhotos(photosArray);
  }, []);

  return (
    <div className="photos-container">
      <IoIosArrowBack
        className="arrow text-blue"
        onClick={isLeftOffsetAvailable ? handleLeftArrowClick : undefined}
        style={{
          opacity: `${isLeftOffsetAvailable ? '1' : '0.4'}`,
          cursor: `${isLeftOffsetAvailable ? 'pointer' : 'default'}`,
        }}
      />
      <div className="main__photos">
        <div className="window">
          <div
            className="all-items-container"
            style={{ transform: `translateX(${offset}px)` }}
          >
            {photos}
          </div>
        </div>
      </div>
      <IoIosArrowForward
        className="arrow text-blue"
        onClick={isRightOffsetAvailable ? handleRightArrowClick : undefined}
        style={{
          opacity: `${isRightOffsetAvailable ? '1' : '0.4'}`,
          cursor: `${isRightOffsetAvailable ? 'pointer' : 'default'}`,
        }}
      />
    </div>
  );
}
