import './header.css';
import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { scroller } from 'react-scroll';
import PhoneMenu from './PhoneMenu';
import Navigation from './Navigation/Navigation';

export default function Header() {
  const [isToggled, handleToggle] = useState('');
  const location = useLocation();

  const handleBurgerClick = () => {
    handleToggle(isToggled ? '' : 'toggled');
    document.documentElement.style.overflowY = '';
  };

  const handleScrollLinkClick = (elementId: string) => {
    handleBurgerClick();
    scroller.scrollTo(elementId, {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
    });
    document.documentElement.style.overflowY = 'scroll';
  };

  useEffect(() => {
    const toggleClass = (value: string) =>
      document.body.classList[value as 'add' | 'remove']('overflow');
    toggleClass(isToggled === 'toggled' ? 'add' : 'remove');
  }, [isToggled, location]);

  return (
    <>
      {/* <div className="header-top bg-blue">
        <div className="header-accreditations">
          <a href="https://thebluebook.com">
            <img src={accreditation1} alt="the blue book" />
          </a>
          <a href="https://www.agc.org/">
            <img src={accreditation2} alt="AGC" />
          </a>
          <a href="http://www.bxwa.com/">
            <img src={accreditation3} alt="BXWA" />
          </a>
          <a href="https://www.bbb.org/">
            <img
              src={accreditation4}
              alt="BBB"
              className="accreditations-svg"
            />
          </a>
        </div>
        <div className="header_estimate-containter">
          <Link to="/contacts" reloadDocument className="header__estimate-text">
            <span>Request an Estimate</span>
          </Link>
          <div className="header__estimate-number bg-light-blue">
            1.206.300.7477
          </div>
        </div>
      </div> */}
      <div className="header-container bg-blue">
        <Link to="/">
          <div className="header__logo" />
        </Link>
        <ul className="header__nav">
          <Navigation onLinkClick={handleBurgerClick} />
        </ul>
        <div className="header__btns ">
          <Link
            to="/about-us"
            onClick={(e) => handleScrollLinkClick('about-us-section')}
            className="btn text-blue"
            reloadDocument
          >
            ABOUT US
          </Link>
          <Link
            to="/#reviews-section"
            onClick={(e) => handleScrollLinkClick('reviews-section')}
            className="btn text-blue"
          >
            REVIEWS
          </Link>
        </div>
        <div className="header_estimate-containter">
          <Link to="/contacts" reloadDocument className="header__estimate-text">
            <span>Request an Estimate</span>
          </Link>
          <div className="header__estimate-number bg-light-blue ">
            1.206.300.7477
          </div>
        </div>
        <div className="header__menu-toggle">
          <button type="button" onClick={handleBurgerClick}>
            <div id={isToggled} className="burger">
              <span />
              <span />
              <span />
            </div>
          </button>
        </div>
      </div>
      <PhoneMenu
        onLinkClick={handleBurgerClick}
        handleScrollLinkClick={handleScrollLinkClick}
        name={isToggled ? 'active' : 'disabled'}
      />
    </>
  );
}
