import { link } from 'fs';
import * as React from 'react';
import { Link } from 'react-router-dom';
import NavigationLink from './NavigationLink';

interface NavigationProps {
  onLinkClick: () => void;
}

type Link = {
  path: string;
  name: string;
};

const links: Link[] = [
  {
    path: '/',
    name: 'Home',
  },
  {
    path: '/services',
    name: 'Services',
  },
  {
    path: '/portfolio',
    name: 'Our Portfolio',
  },
  {
    path: '/contacts',
    name: 'Contacts',
  },
];

export default function Navigation({ onLinkClick }: NavigationProps) {
  return (
    <>
      {links.map(({ path, name }) => (
        <NavigationLink
          path={path}
          name={name}
          onLinkClick={onLinkClick}
          key={name}
        />
      ))}
    </>
  );
}
