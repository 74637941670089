import * as React from 'react';
import { FiX } from 'react-icons/fi';
import './pop-up.css';

interface PopUpProps {
  popupVisibility: boolean;
  setPopupVisibility: (arg: boolean) => void;
  setContactUsSectionVisibility: (arg: boolean) => void;
}

export default function PopUp({
  popupVisibility,
  setPopupVisibility,
  setContactUsSectionVisibility,
}: PopUpProps) {
  const popupClassName = `pop-up-container ${popupVisibility ? 'visible' : ''}`;

  const handleButtonClick = () => {
    setPopupVisibility(false);
    setContactUsSectionVisibility(true);
  };

  return (
    <div className={popupClassName}>
      <FiX className="pop-up-x" onClick={handleButtonClick} />
      <h1>Thank you!</h1>
      <p>Your form was submitted successfully.</p>
      <button
        type="submit"
        className="pop-up-btn btn"
        onClick={handleButtonClick}
      >
        ok
      </button>
    </div>
  );
}
