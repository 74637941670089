import * as React from 'react';
import { Link } from 'react-router-dom';
import { ProjectType } from './Portfolio';
import './portfolio-elem.css';

const numberNames = [
  'first',
  'second',
  'third',
  'fourth',
  'fifth',
  'sixth',
  'seventh',
  'eight',
  'ninth',
];

export default function PortfolioElem({ name, id, images }: ProjectType) {
  const projectLink = `/portfolio/${id}`;
  return (
    <div className="portfolio__elem-container">
      <div className="portfolio__image" id={`portfolio-${numberNames[id - 1]}`}>
        <div className="portfolio__content">
          <h4 className="content__title">{name}</h4>
          <Link className="content__link btn-blue" to={projectLink} reloadDocument >
            View Project
          </Link>
        </div>
      </div>
      <div className="portfolio__content-mobile">
        <h4 className="content__title">{name}</h4>
        <Link className="content__link btn-blue" to={projectLink} reloadDocument >
          View Project
        </Link>
      </div>
    </div>
  );
}
