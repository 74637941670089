import * as React from 'react';
import './about-us.css';
import ValuesPrinciples from './ValuesPrinciples';
import Services from '../Home/Services/Services';

export default function AboutUs() {
  return (
    <div id="about-us-section">
      <div className="about-us__text-background">
        <h1>About Us</h1>
      </div>
      <div className="about-us-container bg">
        <div className="flex">
          <div className="about-us__image" />
          <div className="about-us__text">
            <p>
              Welcome to 1st Choice Construction! We are a team of experienced
              and skilled professionals dedicated to providing high-quality
              construction services to our clients across Washington State. With
              decades of combined experience in the industry, we have built a
              solid reputation for excellence and reliability.
            </p>
            <p>
              Our company offers various building services, including new
              construction, remodeling, renovation, and restoration for
              residential and commercial properties. We specialize in custom
              solutions tailored to our client&apos;s unique needs and
              preferences.
            </p>
            <p>
              Our company is committed to delivering exceptional results on
              every project, big or small. Building or renovating your property
              can be a significant investment. We strive to exceed your
              expectations by using only the highest quality materials and
              state-of-the-art equipment to ensure your project is completed to
              the highest standards.
            </p>
            <p>
              Our team of professionals includes architects, engineers, project
              managers, and skilled craftsmen who work together seamlessly to
              bring your vision to life. We place a strong emphasis on
              communication, transparency, and collaboration to ensure that you
              are informed and involved in every step of the process.
            </p>
            <p>
              We are fully licensed and insured, giving you peace of mind
              knowing that you are working with a reputable and reliable
              building company. Our commitment to safety and adherence to all
              industry standards and regulations ensures that your property is
              in good hands.
            </p>
            <p>
              We invite you to explore our website to learn more about our
              services and view our portfolio of completed projects. Please
              contact us today to schedule a consultation, and let us help you
              bring your dream property to life.
            </p>
          </div>
        </div>
      </div>
      <Services />
      <ValuesPrinciples />
    </div>
  );
}
