import './main.css';
import { Link } from 'react-router-dom';

export default function Main() {
  return (
    <section className="main">
      <div className="main-background">
        <div className="main__text">
          <div className="text-container">
            <h1>Be the 1st in Architecture, Construction, and Beyond</h1>
            <button type="button" className="btn main-btn">
              <Link to="/contacts" reloadDocument>
                Request an Estimate
              </Link>
            </button>
          </div>
        </div>
      </div>
      {/* <PhotosCarousel>
        <div className="photo main__photo-1" />
        <div className="photo main__photo-2" />
        <div className="photo main__photo-3" />
        <div className="photo main__photo-4" />
        <div className="photo main__photo-5" />
        <div className="photo main__photo-6" />
      </PhotosCarousel> */}
    </section>
  );
}
