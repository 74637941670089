import './services.css';
import { Link } from 'react-router-dom';

type ServiceCell = {
  name: string;
  title: string;
};

const servicesCells: ServiceCell[] = [
  {
    name: 'Siding',
    title: 'Siding',
  },
  {
    name: 'Windows',
    title: 'Windows & Doors',
  },
  {
    name: 'Painting',
    title: 'Painting',
  },
  {
    name: 'Building',
    title: 'Flooring & Millwork',
  },
  {
    name: 'Gutters',
    title: 'Gutters',
  },
];

export default function Services() {
  return (
    <div className="services-background">
      <div className="services-container">
        <h1 className="services-title">Services</h1>
        <div className="services-flex">
          {servicesCells.map(({name, title}) => {
            const imageClass = `service-${name
              .split(' ')[0]
              .toLowerCase()} service-img`;
            return (
              <div className="cell">
                <div className="inner">
                  <div className={imageClass} />
                  <h3>{title}</h3>
                  <p>
                    <Link to="/services" className="learn-more-btn">
                      Learn More
                    </Link>
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
