import Services from '../Services/Services';
import './introduction.css';
import PhotosCarousel from '../Carousel/PhotosCarousel';

export default function Extroduction() {
  return (
    <div className="introduction-container">
      <p className="introduction__text align-center">
        Welcome to 1st Choice Construction! We are a team of experienced and
        skilled professionals dedicated to providing high-quality construction
        services to our clients across Washington State. With decades of
        combined experience in the industry, we have built a solid reputation
        for excellence and reliability.
      </p>
      <div className="carousel-container">
        <PhotosCarousel>
          <div className="photo main__photo-1" />
          <div className="photo main__photo-2" />
          <div className="photo main__photo-3" />
        </PhotosCarousel>
        <div className="photo main__photo-1 carousel-phone" />
      </div>
      <p className="introduction__text align-center">
        Our company offers various building services, including new
        construction, remodeling, renovation, and restoration for residential
        and commercial properties. We specialize in custom solutions tailored to
        our client&apos;s unique needs and preferences.
      </p>
      <Services />
      <div className="content-container align-center">
        <p className="introduction__text introduction__text align-center">
          Our team of professionals includes architects, engineers, project
          managers, and skilled craftsmen who work together seamlessly to bring
          your vision to life. We place a strong emphasis on communication,
          transparency, and collaboration to ensure that you are informed and
          involved in every step of the process.
        </p>
        <div className="introduction-img first" />
      </div>
      <div className="content-container align-center">
      <p className="introduction__text align-center phone">
          We are fully licensed and insured, giving you peace of mind knowing
          that you are working with a reputable and reliable building company.
          Our commitment to safety and adherence to all industry standards and
          regulations ensures that your property is in good hands.
        </p>
        <div className="introduction-img second" />
        <p className="introduction__text align-center device">
          We are fully licensed and insured, giving you peace of mind knowing
          that you are working with a reputable and reliable building company.
          Our commitment to safety and adherence to all industry standards and
          regulations ensures that your property is in good hands.
        </p>
      </div>
    </div>
  );
}
