import './phone-menu.css';
import { Link } from 'react-router-dom';
import Navigation from './Navigation/Navigation';

interface PropsType {
  name: string;
  onLinkClick: () => void;
  handleScrollLinkClick: (elementId: string) => void;
}

export default function PhoneMenu({
  name,
  onLinkClick,
  handleScrollLinkClick,
}: PropsType) {
  return (
    <ul id={name} className="phone-menu">
      <Navigation onLinkClick={onLinkClick} />
      <Link
        to="/about-us"
        type="button"
        className="btn"
        onClick={() => handleScrollLinkClick('about-us-section')}
      >
        ABOUT US
      </Link>
      <Link
        to="/#reviews-section"
        type="Link"
        className="btn"
        onClick={() => handleScrollLinkClick('reviews-section')}
      >
        REWIEVS
      </Link>
    </ul>
  );
}
