import * as React from 'react';
import Project from './PortfolioElem';
import './portfolio.css';

export type ProjectType = {
  name: string;
  id: number;
  images: string[];
  description: string;
};

export const projects: ProjectType[] = [
  {
    name: 'Project 1',
    id: 1,
    images: ['img_1_1', 'img_1_2', 'img_1_3', 'img_1_4', 'img_1_5', 'img_1_6'],
    description: '',
  },
  {
    name: 'Project 2',
    id: 2,
    images: ['img_2_1', 'img_2_2', 'img_2_3', 'img_2_4', 'img_2_5', 'img_2_6'],
    description: '',
  },
  {
    name: 'Craftsman',
    id: 3,
    images: [
      'img_3_1',
      'img_3_2',
      'img_3_3',
      'img_3_4',
      'img_3_5',
      'img_3_6',
      'img_3_7',
      'img_3_8',
      'img_3_9',
      'img_3_10',
    ],
    description:
      'High end custom remodeled Craftsman in the heart of Maple Leaf, with EVERYTHING updated with inspections and permits. Impeccable finish with hardwoods, marble, high end appliances with 4 bedrooms including a master suite plus 2.75 bathrooms and much more! A must see for anyone interested in a chance to own in this desirable Maple Leaf neighborhood with off street parking, nice sized lot with very accessible back alley 2-car detached garage with RV parking and an amazing back yard to entertain! ',
  },
  {
    name: 'Project 4',
    id: 4,
    images: [
      'img_4_1',
      'img_4_2',
      'img_4_3',
      'img_4_4',
      'img_4_5',
      'img_4_6',
      'img_4_7',
      'img_4_8',
      'img_4_9',
      'img_4_10',
    ],
    description:
      'Check out this fully updated property with a great income-producing potential. Everything inside is new. Open space concept, vaulted ceiling. Fully fenced, spacious, perfectly leveled and beautifully landscaped backyard. Brand new deck with railings and direct access from multiple points. With second kitchen in the basement and separate entry it can easily be used as a MIL unit if desired. In addition, a detached guest house with its own bathroom makes this property even more lucrative. ',
  },
  {
    name: 'Project 5',
    id: 5,
    images: [
      'img_5_1',
      'img_5_2',
      'img_5_3',
      'img_5_4',
      'img_5_5',
      'img_5_6',
      'img_5_7',
      'img_5_8',
      'img_5_9',
      'img_5_10',
    ],
    description:
      'Come see this amazing Meadowdale property featuring a complete, fully-permitted remodel AND an income producing MIL! This property has it all- beautiful new kitchen, bathrooms and flooring, open concept with vaulted ceilings, fully fenced front AND back yard. Large one- bedroom MIL unit has a separate entrance and can be used as a rental, or main living space if desired. Located within award-winning school district and close proximity to Alderwood mall, Costco and other shopping plazas. ',
  },
  {
    name: 'Project 6',
    id: 6,
    images: [
      'img_6_1',
      'img_6_2',
      'img_6_3',
      'img_6_4',
      'img_6_5',
      'img_6_6',
      'img_6_7',
      'img_6_8',
      'img_6_9',
      'img_6_10',
    ],
    description:
      'BUYER BONUS up to $10,000.00 towards rate buy down!!Check out this tastefully remodeled home that is sitting in a beautiful neighborhood literally across the Cedar Park & Cedar Elementary School.With fully permitted remodel, this house is practically new. Featuring new kitchen cabinets, appliances, quartz countertops; new flooring throughout, fully updated bathrooms, custom doors and handrail; new roof, insulation, siding, heating, deck. Freshly painted inside and out.Daylight basement with full bathrm, two bedrooms, its own kitchen and a separate entrance, can be considered a separate living space.A large perfectly flat yard behind the garage that could be used for building an ADU,playground, pool, gardening, outdoor parties, etc. ',
  },
  {
    name: 'Project 7',
    id: 7,
    images: [
      'img_7_1',
      'img_7_2',
      'img_7_3',
      'img_7_4',
      'img_7_5',
      'img_7_6',
      'img_7_7',
      'img_7_8',
      'img_7_9',
      'img_7_10',
    ],
    description:
      'Welcome to a newly renovated Beacon Hill home, literally overlooking Boeing Field and charming south Seattle. Featuring open concept layout with 4 spacious bedrooms, and a MIL. Fully permited remodel and everything is new! Tasteful, modern upgrades throughout, spacious kitchen, ample light make this home a deal breaker! MIL unit with one bedroom, 3/4 bathroom, its own kitchen, convenienly attached to the main home yet with a separate entrance allows for use as an income-producing unit or can be enjoyed as a recreational space, playroom, separate work area, etc. Fully fenced front yard allows for privacy and seclusion. Conveniently located within minutes to Boeing Field, freeways, lightrail, resaurants and shops. ',
  },
];

export default function Portfolio() {
  return (
    <section id="portfolio" className='bg'>
      <div className="portolio-section-container container">
        <h1>Our Projects</h1>
        <p>
          Our fundamental values are built into our work style - high quality,
          reliable home structures, beautiful design, and energy efficiency -
          will satisfy your most challenging goals. Please browse through a
          handful of projects implemented by our dedicated crews.
        </p>
        <div className="portfolio-container">
          {projects.map(({ name, id, images, description }) => (
            <Project
              description={description}
              images={images}
              name={name}
              id={id}
              key={`portfolio-${name}`}
            />
          ))}
        </div>
      </div>
    </section>
  );
}
