import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';

export interface NavigationLinkProps {
  path: string;
  name: string;
  onLinkClick: () => void;
}

export default function NavigationLink({
  path,
  name,
  onLinkClick,
}: NavigationLinkProps) {
  const [reloadDocument, setRealoadDocument] = useState<boolean>(true);

  useEffect(() => {
    const mediaQuery: MediaQueryList = window.matchMedia('(min-width: 800px)');
    setRealoadDocument(mediaQuery.matches);

    const handler = (event: MediaQueryListEvent) => {
      setRealoadDocument(!event.matches);
    };
    mediaQuery.addEventListener('change', handler);

    return () => {
      mediaQuery.addEventListener('change', handler);
    };
  });

  return (
    <li>
      <Link
        to={path}
        onClick={onLinkClick}
        reloadDocument={reloadDocument}
      >
        {name}
      </Link>
    </li>
  );
}
