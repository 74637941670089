import * as React from 'react';
import Review from './Review';
import './reviews.css';

type Review = {
  firstName: string;
  lastName: string;
  reviewText: string;
};

const reviews: Review[] = [
  {
    firstName: 'Artur',
    lastName: '',
    reviewText:
      'I had an amazing experience working with this construction company! They were professional and knowledgeable and kept me informed throughout the project. The result exceeded my expectations.',
  },
  {
    firstName: 'Cellia',
    lastName: 'Rins',
    reviewText:
      'I highly recommend this construction company! They were able to take my vision and turn it into a reality. Their attention to detail and commitment to quality is awe-inspiring.',
  },
  {
    firstName: 'Dario',
    lastName: '',
    reviewText:
      'The team at this construction company is incredibly skilled and talented. They tackled a complex project easily and delivered exceptional results.',
  },
  {
    firstName: 'Dinami',
    lastName: 'Te',
    reviewText:
      'I was impressed by the professionalism and expertise of this construction company. They made the construction process smooth and stress-free, and I am thrilled with the result.',
  },
  {
    firstName: 'Anna',
    lastName: 'Pardzhiani',
    reviewText:
      'This construction company went above and beyond to ensure my project was completed on time and within budget. Their commitment to customer satisfaction is unparalleled.',
  },
  {
    firstName: 'Dan',
    lastName: '',
    reviewText:
      "I couldn't be happier with the work that this construction company did for me. They transformed my space into something wonderful and functional.",
  },
  {
    firstName: 'Bob',
    lastName: '',
    reviewText:
      'From start to finish, this construction company was a pleasure to work with. Their team was responsive, knowledgeable, and always available to answer any questions I had.',
  },
  {
    firstName: 'Tom',
    lastName: '',
    reviewText:
      'I highly recommend this construction company to anyone looking for high-quality workmanship and exceptional customer service. They truly exceeded my expectations.',
  },
  {
    firstName: 'Som',
    lastName: '',
    reviewText:
      'This construction company is the real deal. They are honest, reliable, and committed to delivering exceptional results. I would work with them again in a heartbeat.',
  },
  {
    firstName: 'Dara',
    lastName: '',
    reviewText:
      'I am so grateful to have found this construction company. They took my vision and turned it into something truly incredible. I cannot recommend them enough!',
  },
];

export default function Reviews() {
  return (
    <div id="reviews-section">
      <h1>Reviews</h1>
      <div className="reviews-container">
        {reviews.map(({ firstName, lastName, reviewText }) => (
          <Review
            firstName={firstName}
            lastName={lastName}
            reviewText={reviewText}
            key={`review-from-${firstName}_${lastName}`}
          />
        ))}
      </div>
    </div>
  );
}
