import * as React from 'react';
import './footer.css';
import { Link, useLocation } from 'react-router-dom';
import accreditation1 from '../data/acreditations/1.png';
import accreditation2 from '../data/acreditations/2.png';
import accreditation3 from '../data/acreditations/3.png';

export default function Footer() {
  const location = useLocation();

  const isContactUsPage = location.pathname === '/contacts';

  return (
    <div className="footer">
      {!isContactUsPage && (
        <div className="footer__blue">
          <h1>Request an Estimate</h1>
          <Link to="/contacts" reloadDocument className="btn-blue">
            Let&apos;s Get Started
          </Link>
        </div>
      )}
      <div className="footer__black">
      <div className="footer-accreditations">
          <a href="https://www.mbaks.com/">
            <img src={accreditation1} alt="MBA" />
          </a>
          <a href="https://www.bbb.org/">
            <img src={accreditation2} alt="BBB" />
          </a>
          <a href="https://builtgreen.net/">
            <img src={accreditation3} alt="Built Green" />
          </a>
        </div>
        <div className="footer-copyright copyright-device">
          © 2023 1st Choice Construction, All Rights Reserved
        </div>
        <div className="footer-contact">
          <h2>Contact</h2>
          <h3>1.206.300.7477</h3>
        </div>
        <div className="footer-copyright copyright-phone">
          © 2023 1st Choice Construction, All Rights Reserved
        </div>
      </div>
    </div>
  );
}
