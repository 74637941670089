import React from 'react';
import { Route, Routes, ScrollRestoration, Outlet } from 'react-router-dom';
import Home from './Home/Home';
import Services from './Services/Services';
import Header from './Header/Header';
import Portfolio from './Portfolio/Portfolio';
import ContactUs from './Contact/ContactUs';
import Footer from './Footer/Footer';
import './app.css';

function App() {
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
      <ScrollRestoration />
    </>
  );
}

export default App;
